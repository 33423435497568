import axios from 'axios';
import { API_ROOT, logApiError } from '@/store/api';


export default {
    namespaced: true,
    actions: {
        async generateCrutReport({ dispatch }, payload) {
            try {
                const res = await axios.post(
                    `${API_ROOT}/reports/crut/`,
                    payload,
                );

                return {
                    data: res['data'],
                };
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async generateQsbsReport({ dispatch }, payload) {
            try {
                await axios.post(
                    `${API_ROOT}/reports/qsbs/`,
                    payload,
                );
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async generateSolarReport({ dispatch }, payload) {
            try {
                const { data } = await axios.post(
                    `${API_ROOT}/reports/solar/`,
                    payload,
                );

                return { data };
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
    },
};
