import Vue from 'vue';

import usAllGeo from '@highcharts/map-collection/countries/us/us-all.geo.json';
import Antd from 'ant-design-vue';
import bugsnag from '@bugsnag/js';
import bugsnagVue from '@bugsnag/plugin-vue';
import Highcharts from "highcharts";
import HighchartsAnnotations from 'highcharts/modules/annotations';
import HighchartsMapModule from 'highcharts/modules/map';
import HighchartsVue from 'highcharts-vue';
import VueIntercom from 'vue-intercom';
import VueShowdown from 'vue-showdown';
const VERSION = process.env.npm_package_version;

import {
    BUGSNAG_API_KEY,
    INTERCOM_APP_ID,
    PRODUCTION,
    debug,
    handleWebpackError,
} from './store/constants';
import './filters';
import router from './router';
import store from './store';
import App from './App.vue';

// Import styles
import 'ant-design-vue/dist/antd.less';
import './assets/less/valur_overrides.less';
import './assets/tailwind.css';


Vue.config.errorHandler = function (err, vm, info) {
    if (debug) {
        console.log('⚠️ Error!', err, vm, info);
    }
    handleWebpackError();
};


if (PRODUCTION) {
    Vue.use(VueIntercom, {appId: INTERCOM_APP_ID});
}

Vue.use(Antd);
Vue.use(VueShowdown, {
    // set default flavor of showdown
    flavor: 'github',
    // set default options of showdown (will override the flavor options)
    options: {
        emoji: false,
    },
});

HighchartsAnnotations(Highcharts);
HighchartsMapModule(Highcharts);
Highcharts.maps['usAllGeo'] = usAllGeo;
require('highcharts/modules/pattern-fill')(Highcharts);
Vue.use(HighchartsVue);

Vue.config.productionTip = false;

// Logging
let bugsnagClient;
if (PRODUCTION) {
    // BugSnag
    bugsnagClient = bugsnag({
        apiKey: BUGSNAG_API_KEY,
        appVersion: VERSION,
        filters: [
            /password.*/,
            /(otp_)?token/,
        ],
    });
    bugsnagClient.use(bugsnagVue, Vue);
}
export {bugsnagClient};

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
