import {
    addOrEditItem,
    getItems,
} from './api';


export default {
    namespaced: true,
    state: {
        oilGasInfo: {},
        riskAnalysisInfo: {},
    },
    mutations: {
        updateOilGasInfo(state, oilGasInfo) {
            state.oilGasInfo = oilGasInfo;
        },
        updateRiskAnalysisInfo(state, riskAnalysisInfo) {
            state.riskAnalysisInfo = riskAnalysisInfo;
        },
    },
    getters: {
        oil_gas_info: state => {
            return state.oilGasInfo || {};
        },
        risk_analysis_info: state => {
            return state.riskAnalysisInfo || {};
        },
    },
    actions: {
        getRiskAnalysisInfo: getItems('questionnaire/risk_analysis/'),
        createRiskAnalysisInfo: addOrEditItem('questionnaire/risk_analysis/'),
        getOilGasInfo: getItems('questionnaire/oil_gas/'),
        createOilGasInfo: addOrEditItem('questionnaire/oil_gas/'),
    },
};
