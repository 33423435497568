import { addOrEditItem, deleteItem, getItems } from '@/store/api';

export default {
    namespaced: true,
    actions: {
        addScenario: addOrEditItem('scenarios/', ''),
        getScenarios: getItems('scenarios/'),
        deleteScenario: deleteItem('scenarios/'),
    },
};
