export default {
    namespaced: true,
    state: {
        guidedPlanningAnswers: [],
        guidedPlanningGraph: '',
        guidedPlanningInfo: {},
        guidedPlanningProgress: 0,
    },
    mutations: {
        updateGuidedPlanningAnswers(state, guidedPlanningAnswers) {
            state.guidedPlanningAnswers = guidedPlanningAnswers;
        },
        updateGuidedPlanningGraph(state, guidedPlanningGraph) {
            state.guidedPlanningGraph = guidedPlanningGraph;
        },
        updateGuidedPlanningInfo(state, guidedPlanningInfo) {
            state.guidedPlanningInfo = guidedPlanningInfo;
        },
        updateGuidedPlanningProgress(state, guidedPlanningProgress) {
            state.guidedPlanningProgress = guidedPlanningProgress;
        },
    },
    getters: {
        guided_planning_answers: state => {
            return state.guidedPlanningAnswers || [];
        },
        guided_planning_graph: state => {
            return state.guidedPlanningGraph || '';
        },
        guided_planning_info: state => {
            return state.guidedPlanningInfo || {};
        },
        guided_planning_progress: state => {
            return state.guidedPlanningProgress || [];
        },
    },
};
