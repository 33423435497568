import { getItems } from '@/store/api';
import { API_ROOT, logApiError}  from '@/store/api';
import axios from 'axios';

export default {
    namespaced: true,
    actions: {
        getSubscriptionPlans: getItems('subscription_plans/'),
        async updateCard({ dispatch, rootGetters }, payload) {
            const userId = rootGetters.user.id;
            try {
                const { data } = await axios.post(
                    `${API_ROOT}/${userId}/subscriptions/${payload.subscription.pk}/update_card/`,
                    payload,
                    rootGetters.axiosConfig,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async subscribe({ dispatch, rootGetters }, payload) {
            const userId = rootGetters.user.id;
            try {
                const { data } = await axios.post(
                    `${API_ROOT}/${userId}/subscriptions/`,
                    payload,
                    rootGetters.axiosConfig,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async unsubscribe({ dispatch, rootGetters }, payload) {
            const userId = rootGetters.user.id;
            try {
                const { data } = await axios.delete(
                    `${API_ROOT}/${userId}/subscriptions/${payload.subscription.pk}/`,
                    rootGetters.axiosConfig,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
    },
};
