import Vue from 'vue';
import {
    accountingNumberFormatter,
    centToDollarFormatter,
    dateFormatter,
    defaultValue,
    formatDecimal,
    indefiniteArticle,
    intcomma,
    mask,
    pluralize,
    stateAbbr,
    stateByAbbr,
    title,
} from './formatters';

Vue.filter('accountingNumberFormatter', accountingNumberFormatter);
Vue.filter('centToDollarFormatter', centToDollarFormatter);
Vue.filter('dateFormatter', dateFormatter);
Vue.filter('defaultValue', defaultValue);
Vue.filter('formatDecimal', formatDecimal);
Vue.filter('indefiniteArticle', indefiniteArticle);
Vue.filter('intcomma', intcomma);
Vue.filter('mask', mask);
Vue.filter('pluralize', pluralize);
Vue.filter('stateAbbr', stateAbbr);
Vue.filter('stateByAbbr', stateByAbbr);
Vue.filter('title', title);
