<template>
    <v-transition
        :animation-duration="animationDuration"
        appear
        :disabled="isAnimationDisabled"
        :enter-animation="enterAnimation"
        :leave-animation="leaveAnimation"
        :mode="animationMode"
    >
        <router-view />
    </v-transition>
</template>

<script>
    import debounce from 'lodash.debounce';

    import { PRODUCTION } from '@/store/constants';
    import { logError } from '@/constants';
    import { mapState } from 'vuex';
    import { needsLogin } from './router';

    import VTransition from '@/components/tw/elements/VTransition.vue';

    export default {
        name: 'app',
        components: {
            VTransition,
        },
        computed: {
            ...mapState('transition_animation', [
                'animationDuration',
                'animationMode',
                'enterAnimation',
                'isAnimationDisabled',
                'leaveAnimation',
            ]),
        },
        watch: {
            // Every time a route changes, refresh the JWT token
            '$route': debounce(function watchRouteChange() {
                this.$store.dispatch('refreshJWT');
            }, 5000),
            '$store.getters.isLoggedIn': {
                immediate: true,
                async handler(val) {
                    if (!val && needsLogin(this.$route.path)) {
                        let next = this.$route.name === 'login' ? '/' : this.$route.path;
                        if (this.$route.query) {
                            const query = [];
                            Object.keys(this.$route.query).forEach(key => {
                                query.push(`${key}=${this.$route.query[key]}`);
                            });
                            next = `${next}?${query.join('&')}`;
                        }
                        this.$router.push({
                            name: 'login',
                            query: {next},
                        });
                    }
                },
            },
        },
        mounted() {
            try {
                if (PRODUCTION) {
                    this.$intercom.boot();
                }
            } catch (e) {
                logError(e);
            }
        },
    };
</script>
