<style lang="css">
    :root {
        --transition-duration: 0.45s;
    }

    /* slideRight animation */
    .slideRight-enter-active, .slideRight-leave-active {
        transition: opacity var(--transition-duration), transform var(--transition-duration);
    }

    .slideRight-enter, .slideRight-leave-to {
        opacity: 0;
        transform: translateX(30%);
    }

    .slideRight-enter-to, .slideRight-leave {
        opacity: 1;
        transform: translateX(0);
    }

    /* slideLeft animation */
    .slideLeft-enter-active, .slideLeft-leave-active {
        transition: opacity var(--transition-duration), transform var(--transition-duration);
    }

    .slideLeft-enter, .slideLeft-leave-to {
        opacity: 0;
        transform: translateX(-30%);
    }

    .slideLeft-enter-to, .slideLeft-leave {
        opacity: 1;
        transform: translateX(0);
    }

    /* slideUp animation */

    .slideUp-enter-active, .slideUp-leave-active {
        transition: opacity var(--transition-duration), transform var(--transition-duration);
    }

    .slideUp-enter, .slideUp-leave-to {
        opacity: 0;
        transform: translateY(-30%);
    }

    .slideUp-enter-to, .slideUp-leave {
        opacity: 1;
        transform: translateY(0);
    }

    /* slideDown animation */

    .slideDown-enter-active, .slideDown-leave-active {
        transition: opacity var(--transition-duration), transform var(--transition-duration);
    }

    .slideDown-enter, .slideDown-leave-to {
        opacity: 0;
        transform: translateY(30%);
    }

    .slideDown-enter-to, .slideDown-leave {
        opacity: 1;
        transform: translateY(0);
    }

    /* expandOut animation */

    .expandOut-enter-active, .expandOut-leave-active {
        transition: opacity var(--transition-duration), transform var(--transition-duration);
    }

    .expandOut-enter, .expandOut-leave-to {
        opacity: 0;
        transform: scale(0.9);
    }

    .expandOut-enter-to, .expandOut-leave {
        opacity: 1;
        transform: scale(1);
    }

    /* srhinkDown animation */

    .shrinkDown-enter-active, .shrinkDown-leave-active {
        transition: opacity var(--transition-duration), transform var(--transition-duration);
    }

    .shrinkDown-enter, .shrinkDown-leave-to {
        opacity: 0;
        transform: scale(1.1);
    }

    .shrinkDown-enter-to, .shrinkDown-leave {
        opacity: 1;
        transform: scale(1);
    }

    /* fadeIn animation */
    .fadeIn-enter-active, .fadeIn-leave-active {
        transition: opacity var(--transition-duration);
    }

    .fadeIn-enter, .fadeIn-leave-to {
        opacity: 0;
    }

    .fadeIn-enter-to, .fadeIn-leave {
        opacity: 1;
    }
</style>

<template>
    <div :style="rootStyles">
        <slot v-if="disabled" />
        <transition-group
            v-else-if="isGroupTransition"
            :appear="appear"
            :leave-active-class="leaveActiveClass"
            :leave-from-class="leaveFromClass"
            :leave-to-class="leaveToClass"
            :name="enterAnimation"
        >
            <slot />
        </transition-group>
        <transition
            v-else
            :appear="appear"
            :leave-active-class="leaveActiveClass"
            :leave-from-class="leaveFromClass"
            :leave-to-class="leaveToClass"
            :mode="mode"
            :name="enterAnimation"
        >
            <slot />
        </transition>
    </div>
</template>

<script>
    import { ANIMATION_LENGTHS, AVAILABLE_ANIMATIONS } from '@/constants';
    const AVAILABLE_MODES = ['out-in', 'in-out', ''];
    const AVAILABLE_DURATIONS = ['short', 'medium', 'long', ''];

    export default {
        props: {
            animationDuration: {
                type: String,
                required: true,
                validator(value) {
                    return AVAILABLE_DURATIONS.includes(value);
                },
            },
            appear: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            enterAnimation: {
                default: '',
                type: String,
                validator(value) {
                    return Object.values(AVAILABLE_ANIMATIONS).includes(value);
                },
            },
            isGroupTransition: {
                type: Boolean,
                default: false,
            },
            leaveAnimation: {
                default: '',
                type: String,
                validator(value) {
                    return Object.values(AVAILABLE_ANIMATIONS).includes(value);
                },
            },
            mode: {
                type: String,
                default: 'out-in',
                validator(value) {
                    return AVAILABLE_MODES.includes(value);
                },
            },
        },
        computed: {
            leaveActiveClass() {
                return `${this.leaveAnimation}-leave-active`;
            },
            leaveFromClass() {
                return `${this.leaveAnimation}-leave`;
            },
            leaveToClass() {
                return `${this.leaveAnimation}-leave-to`;
            },
            transitionDuration() {
                switch (this.animationDuration) {
                    case 'short':
                        return `${ANIMATION_LENGTHS.short / 1000}s`;
                    case 'medium':
                        return `${ANIMATION_LENGTHS.medium / 1000}s`;
                    case 'long':
                        return `${ANIMATION_LENGTHS.long / 1000}s`;
                    default:
                        return `${ANIMATION_LENGTHS.medium / 1000}s`;
                }
            },
            rootStyles() {
                return {
                    '--transition-duration': this.transitionDuration,
                };
            },
        },
    };
</script>
