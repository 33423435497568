import axios from 'axios';
import { API_ROOT, logApiError } from '@/store/api';


export default {
    namespaced: true,
    state: {
        advisorProfile: {},
        advisoryPartnerProfile: {},
        marketingEvents: {},
    },
    mutations: {
        updateAdvisorProfile(state, advisorProfile) {
            state.advisorProfile = advisorProfile;
        },
        updateAdvisoryPartnerProfile(state, advisoryPartnerProfile) {
            state.advisoryPartnerProfile = advisoryPartnerProfile;
        },
        updateMarketingEvents(state, marketingEvents) {
            state.marketingEvents = marketingEvents;
        },
    },
    getters: {
        advisor_profile: state => {
            return state.advisorProfile || {};
        },
        advisory_partner_profile: state => {
            return state.advisoryPartnerProfile || {};
        },
        marketing_events: state => {
            return state.marketingEvents || {};
        },
    },
    actions: {
        async assignScenario({ dispatch, rootGetters }, params) {
            try {
                const { data } = await axios.get(
                    `${API_ROOT}/assign_scenario/${params.scenario_id}/${params.user_id}/`,
                    rootGetters.axiosConfig,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },

        async getAdvisorInfo({ dispatch }, params) {
            try {
                const { data } = await axios.get(
                    `${API_ROOT}/advisor_info/${params.id}/${params.token}/`,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },

        // Get advisor profile based on current Advisory Partner and user instance
        async getAdvisorProfile({ dispatch, rootGetters }, advisoryPartnerId) {
            try {
                const { data } = await axios.get(
                    `${API_ROOT}/advisory/${advisoryPartnerId}/members/current/`,
                    rootGetters.axiosConfig,
                );
                return data;
            } catch (e) {
                // This means user is not an advisor
                const response = e.response;
                if (response && response.status === 403) {
                    return {};
                } else {
                    logApiError(e, dispatch);
                    return false;
                }
            }
        },
        async createOrUpdateAdvisorProfile({ dispatch, rootGetters }, payload = {}) {
            const id = payload.advisor_id;
            let method = 'post';
            let url = `${API_ROOT}/advisory/${payload.advisory_partner_id}/members/`;
            if (id) {
                method = 'patch';
                url = `${url}${id}/`;
            }
            try {
                const { data } = await axios[method](
                    url,
                    payload,
                    rootGetters.axiosConfig,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async listAdvisorClients({ dispatch, rootGetters }, payload) {
            try {
                const { data } = await axios.get(
                    `${API_ROOT}/advisory/${payload.advisory_partner_id}/members/${payload.advisor_id}/clients/`,
                    rootGetters.axiosConfig,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return [];
            }
        },
        async getAdvisorClient({ dispatch, rootGetters }, payload) {
            try {
                const ap_id = payload.advisory_partner_id;
                const a_id = payload.advisor_id;
                const c_id = payload.client_id;
                const { data } = await axios.get(
                    `${API_ROOT}/advisory/${ ap_id }/members/${ a_id }/clients/${ c_id }/`,
                    rootGetters.axiosConfig,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return null;
            }
        },

        // Get advisory partner profile based on current domain
        async getAdvisoryPartnerProfile({ dispatch }) {
            try {
                const { data } = await axios.get(
                    `${API_ROOT}/advisory/current/`,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async updateAdvisoryPartnerProfile({ dispatch, rootGetters }, payload) {
            try {
                const { data } = await axios.patch(
                    `${API_ROOT}/advisory/${payload.advisory_partner_id}/`,
                    payload,
                    rootGetters.axiosConfig,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async listAdvisoryPartnerHistory({ dispatch, rootGetters }, payload) {
            try {
                const { data } = await axios.get(
                    `${API_ROOT}/advisory/${payload.advisory_partner_id}/history/`,
                    rootGetters.axiosConfig,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async listAdvisoryPartnerMembers({ dispatch, rootGetters }, payload) {
            try {
                const { data } = await axios.get(
                    `${API_ROOT}/advisory/${payload.advisory_partner_id}/members/`,
                    rootGetters.axiosConfig,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async deleteAdvisoryPartnerMember({ dispatch, rootGetters }, payload) {
            try {
                await axios.delete(
                    `${API_ROOT}/advisory/${payload.advisory_partner_id}/members/${payload.advisor_id}/`,
                    rootGetters.axiosConfig,
                );
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async updateAdvisoryPartnerMember({ dispatch, rootGetters }, payload) {
            try {
                await axios.patch(
                    `${API_ROOT}/advisory/${payload.advisory_partner_id}/members/${payload.advisor_id}/`,
                    payload,
                    rootGetters.axiosConfig,
                );
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async addAdvisoryPartnerMember({ dispatch, rootGetters }, payload) {
            try {
                const { data } = await axios.post(
                    `${API_ROOT}/advisory/${payload.advisory_partner_id}/members/`,
                    payload,
                    rootGetters.axiosConfig,
                );
                return data;
            } catch (e) {
                // This means user does not exist
                if (e.response.status === 404) {
                    return {};
                } else {
                    logApiError(e, dispatch);
                    return false;
                }
            }
        },
    },
};
