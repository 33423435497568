import { addOrEditItem, deleteItem, getItems } from './api';

export default {
    namespaced: true,
    actions: {
        createUserDocumentItem: addOrEditItem('documents/'),
        deleteUserDocumentItem: deleteItem('documents/'),
        getUserDocuments: getItems('documents/'),
        addSolarActivity: addOrEditItem('solar_activities/'),
        getSolarActivities: getItems('solar_activities/'),
        addSolarActivityDocument: addOrEditItem('solar_activity_documents/'),
        deleteSolarActivityDocument: deleteItem('solar_activity_documents/'),
    },
};
