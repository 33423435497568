import axios from 'axios';
import { logApiError } from '@/store/api';

export default {
    namespaced: true,
    actions: {
        async getYouTubeVideoDetails({ dispatch }, payload) {
            try {
                const url = 'https://youtube.googleapis.com/youtube/v3/videos?part=snippet&part=contentDetails';
                const response = await axios.get(
                    `${ url }&id=${ payload.videoId }&key=${ payload.apiKey }`,
                    payload,
                );
                return response['data'];
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
    },
};
