import axios from 'axios';
import { API_ROOT, logApiError } from '@/store/api';

export default {
    namespaced: true,
    state: {
        headers: {},
    },
    mutations: {
        updateHeaders(state, headers) {
            state.headers = { ...headers };
        },
    },
    getters: {},
    actions: {
        async listUsers({ dispatch, rootGetters }, payloadStr = '') {
            try {
                const { data } = await axios.get(
                    `${API_ROOT}/accounts/${payloadStr !== '' ? `?${payloadStr}` : ''}`,
                    rootGetters.axiosConfig,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async impersonate({ commit, dispatch, rootGetters, rootState, state }, uid) {
            try {
                commit('updateHeaders', rootState.extraHeaders);
                const {data} = await axios.post(
                    `${API_ROOT}/impersonation/${uid}/start/`,
                    {},
                    rootGetters.axiosConfig,
                );
                const headers = {
                    ...state.headers,
                    ...data,
                };
                commit('updateExtraHeaders', headers, { root: true });
                const user = await axios.get(
                    `${API_ROOT}/accounts/${uid}/`,
                    rootGetters.axiosConfig,
                );
                commit('updateImpersonatedUser', user.data, { root: true });
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async stopImpersonate({ commit, dispatch, rootGetters, rootState, state }) {
            try {
                const impersonate = JSON.parse(rootState.extraHeaders['X-Impersonate'] || '{}');
                const uid = impersonate.uid;
                await axios.post(
                    `${API_ROOT}/impersonation/${uid}/stop/`,
                    {},
                    rootGetters.axiosConfig,
                );
                commit('updateExtraHeaders', state.headers, { root: true });
                commit('updateImpersonatedUser', null, { root: true });
                commit('updateHeaders', {});
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
    },
};
