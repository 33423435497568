import axios from 'axios';
import { API_ROOT, logApiError } from '@/store/api';


export default {
    namespaced: true,
    state: {
        articles: {},
    },
    mutations: {
        setArticle(state, { slug, article }) {
            state.articles[slug] = article;
        },
    },
    actions: {
        async getArticle({ dispatch, state, commit }, slug) {
            if (!state.articles[slug]) {
                try {
                    const res = await axios.get(
                        `${API_ROOT}/articles/${slug}/`,
                    );

                    commit('setArticle', { slug, article: res['data'] });
                } catch (e) {
                    logApiError(e, dispatch);
                    return null;
                }
            }

            return state.articles[slug] ?? null;
        },
    },
};
