import { PRODUCTION } from '@/store/constants';

// const mixpanel = require('mixpanel-browser');
// export const MIXPANEL_TOKEN= process.env.VUE_APP_MIXPANEL_TOKEN;


export const trackEvent = (event, data = {}) => {
    if (PRODUCTION) {
        // Temporarily disabled due to misconfiguration - VAL-1382
        // mixpanel.init(MIXPANEL_TOKEN);
        // mixpanel.track(event, data);
    } else {
        console.log(event, data);
    }
};