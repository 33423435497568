import axios from 'axios';
import { API_ROOT, logApiError } from '@/store/api';


export default {
    namespaced: true,
    state: {
        notificationsData: [],
    },
    mutations: {
        updateNotificationsData(state, notificationsData) {
            state.notificationsData = notificationsData;
        },
    },
    getters: {
        notifications_data: state => {
            return state.notificationsData || [];
        },
    },
    actions: {
        async getNotifications({ dispatch, rootGetters }) {
            try {
                const userId = rootGetters.user.id;
                const { data } = await axios.get(
                    `${API_ROOT}/${userId}/notifications/`,
                    rootGetters.axiosConfig,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
        async updateNotification({ dispatch, rootGetters }, payload = {}) {
            const userId = rootGetters.user.id;
            const id = payload.id;
            let method = 'post';
            let url = `${API_ROOT}/${userId}/notifications/`;
            if (id) {
                method = 'patch';
                url = `${url}${id}/`;
            }
            try {
                const { data } = await axios[method](
                    url,
                    payload,
                    rootGetters.axiosConfig,
                );
                return data;
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
    },
};
