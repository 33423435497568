export default {
    namespaced: true,
    state: {
        isAnimationDisabled: true,
        enterAnimation: '',
        leaveAnimation: '',
        animationMode: '',
        animationDuration: '',
    },
    mutations: {
        updateTransitionAnimation(state, transitionAnimation) {
            state.isAnimationDisabled = transitionAnimation.isAnimationDisabled;
            state.enterAnimation = transitionAnimation.enterAnimation;
            state.leaveAnimation = transitionAnimation.leaveAnimation;
            state.animationMode = transitionAnimation.animationMode;
            state.animationDuration = transitionAnimation.animationDuration;
        },
    },
    actions: {
        updateTransitionAnimation({commit}, payload) {
            const {from, to} = payload;

            const transitionAnimation = {
                isAnimationDisabled: !from && !to && true,
                enterAnimation: to?.enterAnimation || '',
                leaveAnimation: from?.leaveAnimation || '',
                animationMode: from?.animationMode || to?.animationMode || '',
                animationDuration: from?.animationDuration || to?.animationDuration|| '',
            };
            commit('updateTransitionAnimation', transitionAnimation);
        },
    },
    getters: {
        transitionAnimation(state) {
            return state;
        },
    },
};
