import axios from 'axios';
import { API_ROOT, logApiError } from '@/store/api';
import { PRODUCTION } from './constants';


export default {
    namespaced: true,
    actions: {
        async addOrEditHubSpotData({ dispatch }, payload) {
            if (PRODUCTION) {
                try {
                    const res = await axios.post(
                        `${API_ROOT}/service/hubspot_update/`,
                        payload,
                    );

                    return {
                        data: res['data'],
                    };
                } catch (e) {
                    logApiError(e, dispatch);
                    return false;
                }
            } else {
                console.log(`HubSpot update: ${JSON.stringify(payload)}`);
            }
        },
    },
};
