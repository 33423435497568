import axios from 'axios';
import { API_ROOT, logApiError } from '@/store/api';
import { PRODUCTION } from './constants';


export default {
    namespaced: true,
    actions: {
        async sendSlackNotification({ dispatch }, payload) {
            if (PRODUCTION) {
                try {
                    const { data } = await axios.post(
                        `${API_ROOT}/notifier/slack/`,
                        payload,
                    );

                    return { data };
                } catch (e) {
                    logApiError(e, dispatch);
                    return false;
                }
            } else {
                console.log(`Slack notification to "${payload.channel}": ${payload.message}`);
                return true;
            }
        },
        async sendMarketingEmailNotification({ dispatch }, payload) {
            try {
                const res = await axios.post(
                    `${API_ROOT}/notifier/marketing/email/`,
                    payload,
                );

                return {
                    data: res['data'],
                };
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },
    },
};
